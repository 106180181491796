<template>
<div class="container-scroller">
    <tostWindow :message="toastMessage" v-if="showT"></tostWindow>
    <modelWindow :baseUrl="baseUrl" :trans="selTrans" v-if="updateCard" @CloseBox="CloseBoxRefund()" @ShowToast="ShowToast"></modelWindow>
    <!-- partial:partials/_navbar.html -->
    <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" style="background: #fff9f4;">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start" style="background: #fff9f4;">
            <a class="navbar-brand brand-logo-mini" href="/"><img src="@/assets/images/favicon.png" alt="logo" style="margin-left: -25px;" /></a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-stretch">
            <div class="search-field d-none d-md-block" style="width:25%">
                <form class="d-flex align-items-center h-100" action="#">
                    <div class="input-group">
                        <input type="text" placeholder="Enter User Id Or Email" class="form-control bg-transparent border-0" v-model="selUser">
                        <div class="input-group-prepend bg-transparent" @click="getUserToken()">
                            <i class="fa fa-search" style="color:#000;cursor: pointer;"></i>
                        </div>
                    </div>
                </form>
            </div>
            <ul class="navbar-nav navbar-nav-right">
                <li class="nav-item nav-profile dropdown">
                    <a class="nav-link" id="profileDropdown" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                        <div class="nav-profile-text">
                            <p class="mb-1 text-black">Yara Admin</p>
                        </div>
                    </a>
                </li>
                <li class="nav-item nav-logout d-none d-lg-block">
                    <a class="nav-link" href="#" @click="Logout($event)">
                        <i class="fa fa-power-off"></i>
                    </a>
                </li>
            </ul>
        </div>
    </nav>
    <!-- partial -->
    <div class="container-fluid page-body-wrapper">
        <!-- partial:partials/_sidebar.html -->
        <nav class="sidebar sidebar-offcanvas" id="sidebar" style="background: #f2edf3;">
            <ul class="nav">
            </ul>
        </nav>
        <!-- partial -->
        <div class="main-panel">
            <div class="content-wrapper">
                <div class="page-header">
                    <h3 class="page-title">
                        <span class="page-title-icon bg-gradient-primary text-white me-2">
                            <i class="fa fa-user-circle"></i>
                        </span><span v-if="userDetails">{{userDetails.name}}</span> <span v-else>Please search a customer to get details.</span>
                    </h3>
                </div>
                <div class="row" v-if="userDetails">
                    <div class="col-md-6 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <div class="">
                                    <p>Yara Id : {{userDetails.id}}</p>
                                    <p>Health Id : {{userDetails.health_id}}</p>
                                    <p v-if="medForm!=null"> User Visit Type : {{ medForm.visit_type }} <span v-if="medForm.visit_type=='async_visit'"> | <strong>User Doesn't Need An Appointment</strong></span></p>
                                    <p> Refill Link  : <a :href="`https://signup.joinyara.com/re-visit?id=${userDetails.id}&health_id=${userDetails.health_id}`">https://signup.joinyara.com/re-visit?id={{userDetails.id}}&health_id={{userDetails.health_id}}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <div class="">
                                    <p>Email : {{userDetails.email}} &nbsp; &nbsp;| &nbsp; &nbsp; Phone : {{userDetails.phone}}</p>
                                    <p v-if="shipping_details">Shipping Address: {{ shipping_details.address_line_1 }}, {{ shipping_details.city }}, {{shipping_details.state}} {{ shipping_details.zip }}</p>
                                    <p v-else>Shipping Address: No Address Found</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="userDetails">
                    <div class="col-md-6 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Appointments & Revisit</h4>
                                <div class="d-flex justify-content-center" v-if="prescription">
                                    No upcoming appointments.
                                </div>
                                <div v-else-if="userDetails.onboard==0 && hasSuccessTransc">
                                    User has not completed Medical Form<br /><br />
                                    Please share this link with Customer to complete Medical Form: <br><br />
                                    <a target="_blank" :href="'https://signup.joinyara.com/get-to-know?health_id='+ userDetails.health_id +'&id='+userDetails.id">https://signup.joinyara.com/get-to-know?health_id={{userDetails.health_id}}&id={{userDetails.id}}</a> <i class="fa fa-copy" style="cursor: pointer;" @click="CopyLink('https://signup.joinyara.com/get-to-know?health_id='+ userDetails.health_id +'&id='+userDetails.id)"></i>
                                </div>
                                <div v-else-if="!hasSuccessTransc">
                                    User has not paid for any program<br /><br />
                                    Please share this link with Customer to complete payment: <br>
                                    <a target="_blank" :href="'https://signup.joinyara.com/checkout?health_id='+ userDetails.health_id +'&id='+userDetails.id">https://signup.joinyara.com/checkout?health_id={{userDetails.health_id}}&id={{userDetails.id}}</a> <i class="fa fa-copy" style="cursor: pointer;" @click="CopyLink('https://signup.joinyara.com/checkout?health_id='+ userDetails.health_id +'&id='+userDetails.id)"></i>
                                </div>
                                <div v-else-if="hasUpcomingAppointments">
                                    Upcoming Appointments: <br><br>
                                    Date: {{ getAppTime(appointments) }}
                                    <br><br>
                                    Link To Join meeting <br><br>
                                    <a target="_blank" :href="appointments.videochat_url">{{ appointments.videochat_url }}</a>
                                </div>
                                <div v-else>
                                    User has no upcoming appointments. Link to schedule an Appointment. <br><br>
                                    <a target="_blank" :href="'https://signup.joinyara.com/schedule?visit_type=initial_visit&health_id='+ userDetails.health_id +'&id='+userDetails.id+'&state='+shipping_details.state">https://signup.joinyara.com/schedule?visit_type=initial_visit&health_id={{userDetails.health_id}}&id={{userDetails.id}}&state={{shipping_details.state}}</a> <i class="fa fa-copy" style="cursor: pointer;" @click="CopyLink('https://signup.joinyara.com/schedule?visit_type=initial_visit&health_id='+ userDetails.health_id +'&id='+userDetails.id+'&state='+shipping_details.state)"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Prescription</h4>
                                <div class="d-flex justify-content-center" v-if="prescription">
                                    {{ priscriptionDrug }} <br /><br />
                                    {{ priscriptionLevel }} <br /><br />
                                    {{priscriptionInstructions}}
                                    <br /><br />
                                    Prescribed On : {{ prescription.created_at.split('T')[0] }}
                                </div>
                                <div v-else>
                                    No Prescription Generated.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" v-if="userDetails">
                    <div class="col-md-6 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Subscription Plan</h4>
                                <div class="d-flex" v-if="subscription">
                                    Subscription Created on : {{ subscription.created_at.split('T')[0] }}<br /><br />
                                    Program : {{ subscription.plan }} <br /><br />
                                    Subscription Status: {{ subscription.status }} <br /><br />
                                    Next Payment Date: {{ subscription.charge_on.split('T')[0] }}
                                    <br />
                                    <br />
                                </div>

                                <div v-else>
                                    User doesn't have an active subscription. <br><br>
                                </div>
                                <div v-if="subscription && prescription==null">
                                    <div class="add-items d-flex" v-if="subscription.status=='active'">
                                        <select class="form-control todo-list-input" style="width: 70%;border: 1px solid #000;">
                                            <option value="" selected disabled>Select Plan</option>
                                            <option value="Tirzepatide">Tirzepatide</option>
                                            <option value="Semaglutide">Semaglutide</option>
                                        </select>
                                        <button class="add btn btn-dark btn-fw" id="add-task">Change Plan</button>
                                    </div>
                                </div>

                                <div v-if="subscription">
                                    <hr />
                                    <div class="add-items d-flex" v-if="subscription.status!='ended'">
                                        <button class="add btn btn-danger btn-fw" @click="cancelSubscription()" id="add-task">Cancel Subscription</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Shipping Details</h4>
                                <div class="" v-if="order_track && order_track.length > 0">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Order#</th>
                                                <th>Tracking#</th>
                                                <th>Carrier</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="order in order_track" :key="order.id">
                                                <td>{{ order?.payload?.type ?? 'N/A' }}</td>
                                                <td>{{ order?.payload?.data?.orderNumber ?? 'N/A' }}</td>
                                                <td v-if="order?.payload?.type === 'order_confirmation'">None</td>
                                                <td v-else>
                                                    <a v-if="order?.payload?.data?.tracking_number" :href="getTrackingLink(order.payload.data.tracking_number)" target="_blank">
                                                        {{ order.payload?.data?.tracking_number }}
                                                    </a>
                                                    <span v-else>N/A</span>
                                                </td>
                                                <td v-if="order?.payload?.type === 'order_confirmation'">None</td>
                                                <td v-else>{{ detectCarrier(order.payload?.data?.tracking_number) }}</td>
                                                <td v-if="order?.payload?.type === 'order_confirmation'">{{ order?.created_at?.split('T')[0] ?? 'N/A' }}</td>
                                                <td v-else>{{ order?.payload?.data?.shipped_date ?? 'N/A' }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div v-else>
                                    No Shipping Details found.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="userDetails">
                    <div class="col-6 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Update Password</h4>
                                <div class="table-responsive">
                                    <form @submit.prevent="UpdatePassword">
                                        <div class="add-items d-flex">
                                            <input class="form-control todo-list-input" type="text" placeholder="Enter new password" v-model="password" required>
                                            <button class="add btn btn-dark btn-fw" id="add-task">Change Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Charge User</h4>
                                <div class="table-responsive">
                                    <form @submit.prevent="MakeCharge">
                                        <div class="add-items d-flex">
                                            <input class="form-control todo-list-input" type="number" placeholder="Enter amount to charge" v-model="amount" required>
                                            <button class="add btn btn-dark btn-fw" id="add-task">Charge</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="userDetails">
                    <div class="col-12 grid-margin">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Recent Payments</h4>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th> Date </th>
                                                <th> Transaction Id </th>
                                                <th> Amount </th>
                                                <th> Status </th>
                                                <th> Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="trans in transactions" :key="trans.id">
                                                <td> {{trans.created_at}} </td>
                                                <td> {{trans.transaction_id}} </td>
                                                <td> ${{trans.amount}} </td>
                                                <td>
                                                    <label class="badge badge-gradient-success" v-if="trans.status=='success'">{{trans.status}}</label>
                                                    <label class="badge badge badge-gradient-danger" v-if="trans.status=='failed'">{{trans.status}}</label>
                                                    <label class="badge badge-gradient-info" v-if="trans.status=='refund'">{{trans.status}}</label>
                                                </td>
                                                <td> <span v-if="trans.status=='success'"> <button type="submit" @click.prevent="setRefund(trans)" class="btn btn-dark btn-fw">Refund</button> </span> <span v-else> No Action Req. </span> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <footer class="footer" style="background: #f2edf3;">
                <div class="d-sm-flex justify-content-center justify-content-sm-between">
                    <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2024 <a href="https://joinyara.com/" target="_blank">Yara</a>. All rights reserved.</span>
                </div>
            </footer>
        </div>
    </div>
</div>
</template>

<script>
import tostWindow from './tostWindow.vue'
import modelWindow from './modelWindow.vue'
import moment from 'moment-timezone';
export default {
    props: ['baseUrl'],
    name: 'HomePage',
    components: {
        tostWindow,
        modelWindow
    },
    data() {
        return {
            user: [],
            transactions: [],
            admin_notes: [],
            userDetails: null,
            notrans: false,
            subscription: null,
            appointments: null,
            cancelSubs: false,
            loading: true,
            winkey: 0,
            lab: null,
            prescription: null,
            updateCard: false,
            shipping_details: null,
            toastMessage: '',
            showT: false,
            isopen: false,
            tracking: null,
            selTrans: null,
            order_track: null,
            selUser: '',
            password: '',
            amount: 0,
            medForm:null,
        }
    },
    computed: {

        hasUpcomingAppointments() {
            try {
                const appointmentDate = new Date(this.appointments.app_date);
                const now = new Date();
                return appointmentDate > now;
            } catch (error) {
                return false;
            }
        },
        hasSuccessTransc() {
            try {
                return this.transactions.some(transaction => transaction.status === 'success');
            } catch (error) {
                return false;
            }
        },
        priscriptionLevel() {
            try {
                let payload = this.prescription.payload;

                let k = payload.find(element => element.label == "Medication/Level");

                return "Medication/Level : " + k.answer;
            } catch (error) {
                console.log(error);
                return "";
            }
        },
        priscriptionDrug() {
            try {
                let payload = this.prescription.payload;
                let k = payload.find(element => element.label == "Drug Name");

                return "Drug Name : " + k.answer;
            } catch (error) {
                console.log(error);
                return "";
            }
        },
        priscriptionInstructions() {
            try {
                let payload = this.prescription.payload;
                let k = payload.find(element => element.label == "Instructions");

                return "Usage Instructions : " + k.answer;
            } catch (error) {
                console.log(error);
                return "";
            }
        },
        lastVisit() {
            if (this.appointments == null) {
                return false;
            }
            try {
                var startDate = moment(this.appointments.app_date, "YYYY-MM-DDTHH:mm:ssZ");
                var currentDate = moment();
                var daysPast = currentDate.diff(startDate, 'days');
                return daysPast > 21;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        submittedUser() {
            return JSON.parse(localStorage.getItem('selectedUser'));
        },
        inFuture() {
            try {
                if (this.appointments == null) {
                    return true;
                }
                const targetMoment = moment(this.appointments.app_date);
                const currentMoment = moment();
                return targetMoment.isAfter(currentMoment);
            } catch (error) {
                console.log(error);
                return true;
            }

        }
    },
    methods: {
        getTrackingLink(trackingNumber){
            const upsPattern = /^1Z[0-9A-Z]{16}$/;
                const uspsPattern = /^(94|95|96|97|98|99)[0-9]{18,20}$/;
                const fedexPattern = /^(\d{12}|\d{15})$/;
                const dhlPattern = /^\d{10}$/;
            
                if (upsPattern.test(trackingNumber)) {
                    return `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}`
                } else if (uspsPattern.test(trackingNumber)) {
                    return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingNumber}`
                } else if (fedexPattern.test(trackingNumber)) {
                    return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`
                } else if (dhlPattern.test(trackingNumber)) {
                    return `https://www.dhl.com/us-en/home/tracking.html?tracking-id=${trackingNumber}`
                } else {
                    return '#';
                }
        },
        detectCarrier(trackingNumber) {
                const upsPattern = /^1Z[0-9A-Z]{16}$/;
                const uspsPattern = /^(94|95|96|97|98|99)[0-9]{18,20}$/;
                const fedexPattern = /^(\d{12}|\d{15})$/;
                const dhlPattern = /^\d{10}$/;
            
                if (upsPattern.test(trackingNumber)) {
                    return 'UPS';
                } else if (uspsPattern.test(trackingNumber)) {
                    return 'USPS';
                } else if (fedexPattern.test(trackingNumber)) {
                    return 'FedEx';
                } else if (dhlPattern.test(trackingNumber)) {
                    return 'DHL';
                } else {
                    return 'Unknown carrier';
                }
        },
        MakeCharge(){
            window.axios.post(this.baseUrl + '/charge-user', {
                id: this.userDetails.id,
                amount: this.amount
            }).then(response => {
                if (response.data.status == 'error') {
                    this.toastMessage = response.data.message
                    this.showT = true;
                } else {
                    this.amount = 0;
                    this.toastMessage = "Success.. you can reload the page to view new record."
                    this.showT = true;
                }
            });
        },
        UpdatePassword() {
            window.axios.post(this.baseUrl + '/update-password', {
                id: this.userDetails.id,
                password: this.password
            }).then(response => {
                if (response.data.status == 'error') {
                    this.toastMessage = "An error occurred while updating."
                    this.showT = true;
                } else {
                    this.toastMessage = "Updated password"
                    this.showT = true;
                }
            });
        },
        cancelSubscription() {
            window.axios.post(this.baseUrl + '/cancle-subs', {
                id: this.userDetails.id,
                sf_id: this.userDetails.sf_id,
                reason: "Customer Request. Cancelled by Customer Care",
                reccomend: "NA"
            }).then(response => {
                if (response.data.status == 'error') {
                    this.toastMessage = "An error occurred while canceling."
                    this.showT = true;
                } else {
                    this.toastMessage = "Subscription Cancelled"
                    this.showT = true;
                }
            });
        },
        CloseBoxRefund() {
            this.selTrans = null;
            this.updateCard = false;
        },
        setRefund(trans) {
            this.selTrans = trans;
            this.updateCard = true;
        },
        CopyLink(link) {
            navigator.clipboard.writeText(link).then(() => {
                this.toastMessage = "Link copied"
                this.showT = true;
            }).catch(err => {
                console.error('Failed to copy: ', err);
            });
        },
        getAppTime(appointment) {
            try {
                const appointmentDate = moment.tz(appointment.app_date, appointment.app_timezone);
                const formattedDate = appointmentDate.format('MMMM Do YYYY, h:mm:ss a z');
                return formattedDate;
            } catch (error) {
                return appointment.app_date;
            }
        },
        getUserToken() {
            localStorage.setItem('selectedUser', JSON.stringify(this.selUser));
            this.getData();
        },
        OpenMenu() {
            this.isopen = !this.isopen;
        },
        sendToPage(event, url) {
            event.preventDefault();
            window.open(url, '_blank');
            this.OpenMenu();
        },

        ShowToast() {
            this.toastMessage = "Success! Refund Processed"
            this.showT = true;
            this.updateCard = false;
        },
        toggleUpdate(event) {
            event.preventDefault();
            this.updateCard = !this.updateCard;
        },
        applyRefund(event, id) {
            event.preventDefault();
            console.log(id);
        },
        getDate(date) {
            return moment(date).format('MMMM D, YYYY')
        },
        getAppDate(date_of_appointment, timezone) {
            return moment(date_of_appointment).tz(timezone).format('MMMM Do, YYYY @ hh:mm A z')
        },
        getDateInFormat(utcTimestampISO) {
            const userTimeZone = moment.tz.guess(); // Automatically detect user's timezone
            const localDateTime = moment.utc(utcTimestampISO).tz(userTimeZone);
            return moment(localDateTime).format("MMMM Do, YYYY [@] h A z");
        },
        isDataExpired() {
            let storedData = localStorage.getItem('lastSync');
            if (storedData) {
                storedData = JSON.parse(storedData);
                let unixTime = Math.floor(Date.now() / 1000);
                var differenceInSeconds = Math.abs(unixTime - storedData) / 1000;
                var fifteenMinutesInSeconds = 15 * 60;
                return differenceInSeconds > fifteenMinutesInSeconds;
            }
            return true;
        },
        getData() {
            window.axios.get(this.baseUrl + '/details/' + this.submittedUser).then(response => {
                if (response.data.status == 'success') {
                    this.userDetails = response.data.user;
                    this.transactions = response.data.transactions;
                    this.subscription = response.data.subscriptions;
                    this.appointments = response.data.appointment;
                    this.lab = response.data.lab;
                    this.prescription = response.data.priscription;
                    this.admin_notes = response.data.admin_notes
                    this.medForm = response.data.medForm;
                    try {
                        this.shipping_details = response.data.shipping.shipping_details;
                    } catch (error) {
                        console.log(error)
                    }

                    this.tracking = response.data.tracking;
                    this.order_track = response.data.order_track;
                    this.loading = false;
                    this.winkey++;
                } else {
                    this.notrans = true;
                    this.winkey++;
                }
            });
        },
        Logout(event) {
            event.preventDefault();
            window.axios.get(this.baseUrl + '/logout').then(response => {
                if (response.data.status == 'success') {
                    localStorage.removeItem('_ymain_key_');
                    window.location.reload();
                }
            }).catch(error => {
                console.log(error);
                localStorage.removeItem('_ymain_key_');
                window.location.reload();
            });
        }
    },
    created() {

    }
}
</script>

<style scoped>
.navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini img {
    width: 70px;
    max-width: 100%;
    height: 28px;
    margin: auto;
}

.containeruser {
    display: flex;
    width: 50%;
}

.text-field-user {
    width: 50%;
    height: 40px;
    /* Increase height */
    border-radius: 10px;
    /* Rounded corners */
    padding: 5px;
    margin-right: 20px;
}

.submit-user {
    width: 50%;
    height: 40px;
    /* Increase height */
    font-size: 15px;
    border-radius: 10px;
    /* Rounded corners */
    cursor: pointer;
    background-color: #95b9c2 !important;
    border: 1px solid #95b9c2 !important;
    color: #fff
}

.dash-button {
    height: 45px;
}

span.transtatus:first-letter {
    text-transform: uppercase;
}

.displaymenu {
    height: 2612.12px;
    display: block;
}

.trans {
    transform: translateY(0px) translateX(0px);
    transition: transform 400ms ease 0s;
}
</style>
